import { CapabilityFetchResult } from "@/types/capabilities";
import { Reply, SendFileError, SendMessageResult } from "@/types/messaging";
import { generateRandomString } from "@/utils/helpers/Utils";
import { isChatbot } from "@/utils/helpers/chatbots";
import {
  fetchWithDigestAuthentication,
  getMimeType,
} from "@/utils/helpers/fileUtils";
import { formatPhoneNumber } from "@/utils/helpers/formatPhoneNumber";
import { fetchCaps } from "@/utils/helpers/loginAndCaps/capabilities";
import { generateLocationPayload, generateMapUrl } from "@/utils/map";
import { nanoid } from "nanoid";
import {
  getMaxFileSizeAllowed,
  getTextFromSuggestionResponse,
  sendFile,
  sendLocation,
  sendTextMessage,
} from "..";
import { SuggestionResponse } from "../../../components/chatScreen/chat/typings";
import WebGwContact from "../../helpers/WebGwContact";
import { getLocalUser } from "../../helpers/localstorage";
import { ComposingNotification } from "../../helpers/notificationChannel";
import NmsMessage from "../NmsMessage";
import { deleteMessages } from "../deleteMessages";
import { conversationsState } from "./ConversationState";
import {
  fileToBase64,
  getMainFilePayload,
  getMainFileUrl,
  setConversationMessageAsFailed,
  setConversationMessagePartial,
} from "./conversationUtils";
import {
  cleanPhoneNumber,
  isPhoneNumberAGroupContributionId,
  isSamePhoneNumber,
} from "./conversationUtils/phoneNumberUtils";
import { updateConversationInDatabase } from "./conversationUtils/updateConversationInDatabase";
import { findConversationByPhoneNumber } from "./findConversationByPhoneNumber";

export const DEFAULT_GROUP_CHAT_NAME = "Group Chat";

type Timeout = number;

/**
 * valtio takes over this class since ``Conversation``s are placed inside a proxyMap.
 * Making changes to internal properties like ``messages`` will update the conversation's state and cause a rerender.
 */
export default class Conversation {
  /** for UI purposes */
  public _isBeingDeleted = false;

  // Either the phone number of the conversation or the group contribution id
  public id: string;

  private isGroupChat: boolean;

  private name: string;

  private iconUrl: string;

  private isLocalUserAdmin: boolean;

  private isLocalUserJoined: boolean;

  private contactAdminId?: string;

  private messageIds!: Set<string>;

  public messages!: NmsMessage[];

  public composers: [WebGwContact, Timeout][] = [];

  public participants: [WebGwContact, ...WebGwContact[]];

  // Used to rejoin group chat
  private conferenceUri?: string;

  // Keep constructor private, only way to create a new conversation is using method getOrCreate
  private constructor(
    phoneNumber: string,
    participants: [WebGwContact, ...WebGwContact[]],
    messages: NmsMessage[],
    isGroupChat = false,
    name = "",
    iconUrl = "",
    isLocalUserAdmin = false,
    isLocalUserJoined = false,
    contactAdmin: string | undefined = undefined,
    conferenceUri: string | undefined = undefined
  ) {
    this.participants = participants;
    this.construct();
    this.setMessages(messages);
    this.isGroupChat = isGroupChat;
    this.name = name;
    this.iconUrl = iconUrl;
    this.isLocalUserAdmin = isLocalUserAdmin;
    this.isLocalUserJoined = isLocalUserJoined;
    this.contactAdminId = contactAdmin;
    this.conferenceUri = conferenceUri;
    this.id = isGroupChat
      ? phoneNumber
      : Conversation.formatPhoneNumberForId(phoneNumber);
  }

  private construct() {
    // this.participants ??= []; // this can't be undefined
    if (!(this.messageIds instanceof Set)) {
      this.messageIds = new Set();
    }

    return this;
  }

  public deepClone() {
    return new Conversation(
      this.id,
      this.participants.slice() as typeof this.participants,
      this.messages.slice(),
      this.isGroupChat,
      this.name,
      this.iconUrl,
      this.isLocalUserAdmin,
      this.isLocalUserJoined,
      this.contactAdminId,
      this.conferenceUri
    );
  }

  public replaceMessageId(
    oldMessageId: string,
    newMessageId: string,
    discardOldMessageId: boolean
  ) {
    if (discardOldMessageId) {
      this.messageIds.delete(oldMessageId);
    }
    this.messageIds.add(newMessageId);
  }

  private setMessages(messages: NmsMessage[]) {
    if (messages[0] instanceof NmsMessage) {
      this.messages = messages;
    } else {
      this.messages = NmsMessage.toMsgList(messages);
    }
    for (const message of this.messages) {
      this.messageIds.add(message["imdn.Message-ID"]);

      // Reactions are regular messages on network side, we track their ids
      if (message.reactions) {
        for (const reactionMessages of Object.values(message.reactions)) {
          for (const reaction of reactionMessages) {
            this.messageIds.add(reaction["imdn.Message-ID"]);
          }
        }
      }

      // Similarily for messages history, keep track of their ids
      for (const history of message.history) {
        this.messageIds.add(history["imdn.Message-ID"]);
      }
    }
  }

  public getName(useDefaultGroupChatName = false) {
    return this.isGroupChat
      ? this.name || (useDefaultGroupChatName ? DEFAULT_GROUP_CHAT_NAME : "")
      : this.participants[0].noNameReturnPhoneNumber();
  }

  public getIsGroupChat() {
    return this.isGroupChat;
  }

  public getIconUrl() {
    return this.iconUrl;
  }

  public getIsLocalUserAdmin() {
    return this.isLocalUserAdmin;
  }

  public getIsLocalUserJoined() {
    return this.isLocalUserJoined;
  }

  public getContactAdminId() {
    return this.contactAdminId;
  }

  public getConferenceUri() {
    return this.conferenceUri;
  }

  public getMessages() {
    return this.messages as Readonly<typeof this.messages>;
  }

  /**
   * Gets the message with the provided message id, either from its own message id or its original message id in case of edit
   */
  public getMessage(messageId: string, lookupHistory = true) {
    return this.getMessages().findLast((message) =>
      message.hasMessageId(messageId, lookupHistory)
    );
  }

  /**
   * Gets the message index with the provided message id, either from its own message id or its original message id in case of edit
   */
  public getMessageIndex(messageId: string, lookupHistory = true) {
    return this.messages.findLastIndex((message: NmsMessage) =>
      message.hasMessageId(messageId, lookupHistory)
    );
  }

  public getLastMessage() {
    return this.messages.at(-1);
  }

  public getLastInMessage() {
    return this.messages.findLast((m) => m.Direction === "In");
  }

  public getLastOutMessage() {
    return this.messages.findLast((m) => m.Direction === "Out");
  }

  /**
   *
   * @param message
   * @param ignoreIdSet
   * @returns true if the message was added, false if it was ignored or used to update the message
   */
  public pushMessage(
    message: NmsMessage,
    ignoreIdSet?: Set<string>,
    updateDatabase = true
  ): boolean {
    /**
     * Conversation objects (messages, etc) are always used through the proxy, make sure to always use it here
     * otherwise using this function from inside another one from a Conversation object directly wont be seen from the update database function
     * below (that uses the proxy)
     */
    const conversation = conversationsState.conversations.get(
      this.id
    ) as Conversation;

    const msgId = message["imdn.Message-ID"];

    if (message["Reference-ID"]) {
      if (message["Reference-Type"] === "Delete") {
        setConversationMessagePartial({
          msgId: message["Reference-ID"],
          newMessage: { deleted: true },
          conversationId: conversation.id,
          updateDatabase,
        });

        return false;
      }

      const replaceId = message["Reference-ID"];
      if (message["Reference-Type"] === "Recall") {
        conversation.removeMessages([message["Reference-ID"]], updateDatabase);
        return false;
      }

      if (message["Reference-Type"] === "Edit") {
        if (
          !conversation.messageIds.has(replaceId) ||
          conversation.messageIds.has(msgId) ||
          ignoreIdSet?.has(msgId)
        ) {
          // Bad edit, ignore
          return false;
        }

        // Don't keep references as this could be an edit over a reply, information will get lost
        delete message["Reference-ID"];
        delete message["Reference-Type"];

        // Keep track of latest message in history
        const refMessage = this.getMessage(replaceId);

        if (!refMessage) {
          // Ref message not found, probably got deleted, ignore this edit
          return false;
        }

        message.history.push(refMessage);

        const setId = setConversationMessagePartial({
          msgId: replaceId,
          newMessage: message,
          conversationId: conversation.id,
          updateDatabase,
          discardOldMessageId: false,
        });
        if (!setId) {
          console.error(
            "Failed to set message id after sending message",
            message
          );
        }
        return false;
      }
    }
    // Reaction case
    if (message.getReactionType() === "ADD") {
      const msgId = message["imdn.Message-ID"];

      if (conversation.messageIds.has(msgId) || ignoreIdSet?.has(msgId)) {
        return false;
      }

      const reactionKey =
        message["Reference-Type"] === "+Custom-Reaction"
          ? getMainFilePayload(message)?.href
          : message.getText();

      if (!reactionKey) {
        console.error("Bad emoji found for ", message, ", ignoring");
        return false;
      }

      setConversationMessagePartial({
        msgId: message["Reference-ID"]!,
        newMessage: {
          reactions: { [reactionKey]: [message] },
        },
        conversationId: conversation.id,
        updateDatabase,
      });

      conversation.messageIds.add(msgId);

      return true;
    }

    if (conversation.messageIds.has(msgId) || ignoreIdSet?.has(msgId)) {
      let didReplacement = false;

      /**
       * Message exists but imdn present, we always trust the latest info got from the network
       * This will likely happen when a message is coming from nms because it was sent from another device connected with the same number, so the flow will be:
       * 1 - got message from nms with no imdn
       * 2 - got same message with imdn information
       * 3 - got same message with updated payloadParts (will replace data url with nms url)
       */
      if ((message.imdns?.imdn?.length || 0) > 0) {
        console.log(
          "Message",
          msgId,
          "already exists but imdn present, we override the information."
        );

        setConversationMessagePartial({
          msgId,
          newMessage: {
            imdns: message.imdns,
          },
          conversationId: conversation.id,
          updateDatabase: false,
        });
        didReplacement = true;
      }

      if (message.Direction === "Out") {
        console.log(
          "Message",
          msgId,
          "already exists but is an outgoing message, updating payloadParts."
        );

        setConversationMessagePartial({
          msgId,
          newMessage: {
            payloadParts: message.payloadParts,
          },
          conversationId: conversation.id,
          updateDatabase: false,
          // Only replace payload parts for pure message id equality, otherwise a message could replace its edited version
          matchOriginalMessage: false,
        });
        didReplacement = true;
      }

      // Flags are important for incoming messages to know if we read them already
      if (
        message.Direction === "In" &&
        (message.flags?.flag?.length || 0) > 0
      ) {
        console.log(
          "Message",
          msgId,
          "already exists but flags present, we override the information."
        );

        setConversationMessagePartial({
          msgId,
          newMessage: {
            flags: message.flags,
          },
          conversationId: conversation.id,
          updateDatabase: false,
        });
        didReplacement = true;
      }

      if (didReplacement) {
        updateConversationInDatabase(conversation.id);
      } else {
        console.warn("Message", msgId, "already exists, ignoring it");
      }

      return false;
    }

    if (!message.getReactionType()) {
      conversation.messages.push(message);
      conversation.messageIds.add(msgId);
    }

    if (updateDatabase) {
      updateConversationInDatabase(this.id);
    }

    return true;
  }

  private reactionHasId(parent: NmsMessage, id?: string) {
    if (!parent.reactions) return undefined;

    return Object.entries(parent.reactions).find(([_, arr]) => {
      return arr.find((nmsMsg) => nmsMsg["imdn.Message-ID"] === id);
    });
  }
  private removeReaction(message: NmsMessage, updateDatabase = true): boolean {
    const emoji = message.getText()!;
    const isCustomReaction =
      message["Reference-Type"]?.endsWith("Custom-Reaction");

    if (!emoji && !isCustomReaction) {
      return false;
    }

    const conversation = conversationsState.conversations.get(
      this.id
    ) as Conversation;

    let deleted = false;

    /**
     * Remove reaction can be called for:
     * - text emojis (-/+Reaction)
     * - -Custom-Reactions coming from network -> take Reference-ID since it refers to the imdn message id of the initial +Custom-Reaction
     * - +Custom-Reactions coming from local -> directly take imdn.Message-ID
     */
    const parentMessage = conversation.messages.find((parent) =>
      isCustomReaction
        ? this.reactionHasId(
            parent,
            message["Reference-Type"] === "-Custom-Reaction"
              ? message["Reference-ID"]
              : message["imdn.Message-ID"]
          )
        : parent.getOriginalMessageId() === message["Reference-ID"]
    );

    if (parentMessage && parentMessage.reactions) {
      const reactions = isCustomReaction
        ? this.reactionHasId(
            parentMessage,
            message["Reference-Type"] === "-Custom-Reaction"
              ? message["Reference-ID"]
              : message["imdn.Message-ID"]
          )
        : Object.entries(parentMessage.reactions).find(([key, _]) => {
            return key === emoji;
          });

      if (reactions && reactions[1] && Array.isArray(reactions[1])) {
        const indexToDelete = reactions[1].findIndex((current) => {
          return isSamePhoneNumber(current.From, message.From);
        });

        if (indexToDelete !== -1) {
          reactions[1].splice(indexToDelete, 1);

          // No more user reaction for this emoji, delete it
          if (reactions[1].length === 0) {
            delete parentMessage.reactions[reactions[0]];
          }

          deleted = true;
        }
      }
    }

    if (deleted) {
      conversation.messageIds.delete(message["imdn.Message-ID"]);
    }

    if (updateDatabase) {
      updateConversationInDatabase(this.id);
    }
    return deleted;
  }

  public removeMessage(message: NmsMessage, updateDatabase = true): boolean {
    if (message.getReactionType()) {
      return this.removeReaction(message, updateDatabase);
    } else {
      return this.removeMessages([message["imdn.Message-ID"]], updateDatabase);
    }
  }

  public removeMessages(
    msgIds: string[] | IterableIterator<string>,
    updateDatabase = true
  ): boolean {
    const conversation = conversationsState.conversations.get(
      this.id
    ) as Conversation;
    let deleted = false;
    for (const msgId of msgIds) {
      if (conversation.messageIds.has(msgId)) {
        conversation.messageIds.delete(msgId);
        const indexToRemove = conversation.getMessageIndex(msgId);
        if (indexToRemove !== -1) {
          conversation.messages.splice(indexToRemove, 1);
          deleted = true;
        }
      }
    }

    if (updateDatabase) {
      updateConversationInDatabase(this.id);
    }
    return deleted;
  }

  public handleComposingNotification(chatNotification: ComposingNotification) {
    const senderAddress = chatNotification.senderAddress;

    const contact = this.participants.find(
      (participant) => !!participant.filterContactOnPhone(senderAddress)
    );

    if (!contact) {
      console.debug(
        "contact not found. maybe they are not a part of this conversation",
        contact
      );
      return;
    }

    switch (chatNotification.isComposing.state) {
      case "active":
        this.addComposer(contact, chatNotification.isComposing.refresh * 1000);
        break;
      case "idle":
        this.removeComposer(contact);
        break;
      default:
        console.error("Invalid isComposing state", chatNotification);
    }
  }

  public addComposer(composer: WebGwContact, timeout: number) {
    console.debug("Adding composer", composer, "with timeout", timeout);

    const t = +setTimeout(() => {
      console.debug("timeout deleting composer", composer);
      this.removeComposer(composer);
    }, timeout);
    for (let i = 0; i < this.composers.length; i++) {
      if (this.composers[i][0] === composer) {
        clearTimeout(this.composers[i][1]);
        this.composers[i][1] = t;
        // Has composer, early return
        return false;
      }
    }
    this.composers.unshift([composer, t]);
    return true;
  }

  public removeComposer(composer: WebGwContact) {
    console.debug("Removing composer", composer);

    for (let i = 0; i < this.composers.length; i++) {
      if (this.composers[i][0] === composer) {
        clearTimeout(this.composers[i][1]);
        this.composers.splice(i, 1);
        return true;
      }
    }
    return false;
  }

  // private fetchPeerCapsPromise: Promise<any> | undefined;
  public fetchPeerCaps(): Promise<CapabilityFetchResult | undefined>[] {
    return this.participants.map((participant) => {
      // ? might have to wait for the caps to show up in the notification channel
      // TODO won't work with multiple numbers
      const phoneNumber = participant.getMainPhoneNumber();
      console.log("fetching caps for Conversation:", phoneNumber, "forcing...");
      return fetchCaps(phoneNumber, true);
    });
  }

  public async createGroupChatAndSendTextMessage(
    message: string,
    groupChatSubject?: string,
    groupChatIconUrl?: string,
    reply?: Reply
  ): Promise<SendMessageResult | undefined> {
    const sendMessageResult = await this._sendTextMessage(
      message,
      false,
      true,
      reply,
      groupChatSubject,
      groupChatIconUrl
    );

    if (sendMessageResult && sendMessageResult.contributionId) {
      this.updateGroupInformation({
        contributionId: sendMessageResult.contributionId,
        subject: groupChatSubject,
        iconUrl: groupChatIconUrl,
      });
    }

    return sendMessageResult;
  }

  public async startChatbotConversation(): Promise<
    SendMessageResult | undefined
  > {
    const browserLanguage = navigator.language.substring(0, 2);
    return this.sendTextMessage(
      '{"response": {"reply": {"displayText": "Chat", "postback": {"data": "new_bot_user_initiation_' +
        browserLanguage +
        '"}}}}',
      true
    );
  }

  public async recallMessage(
    messageId: string
  ): Promise<SendMessageResult | undefined> {
    console.log("Recall message ", messageId);
    const res = await this.sendTextMessage("", false, {
      id: messageId,
      type: "Recall",
    });

    if (res) {
      void this.deleteMessage(messageId);
    }

    return res;
  }

  public async deleteMessage(messageId: string) {
    console.log("Delete message ", messageId);

    const message = this.getMessage(messageId);

    if (message) {
      message.destruct();
      const messageIds = [
        message["imdn.Message-ID"],
        ...message.history.map((history) => {
          // History could not be initialized with all prototype, check if method exists
          if (history.destruct) {
            history.destruct();
          }

          return history["imdn.Message-ID"];
        }),
      ];
      this.removeMessages(messageIds);
      await deleteMessages(messageIds);
    }
  }

  public async softDeleteMessage(
    messageId: string
  ): Promise<SendMessageResult | undefined> {
    console.log("Soft delete message ", messageId);

    const res = await this.sendTextMessage("", false, {
      id: messageId,
      type: "Delete",
    });

    if (res) {
      setConversationMessagePartial({
        msgId: messageId,
        newMessage: { deleted: true },
        conversationId: this.id,
      });
    }

    return res;
  }

  public async sendTextMessage(
    message: string,
    chatbotResponse: boolean,
    reply?: Reply
  ): Promise<SendMessageResult | undefined> {
    return this._sendTextMessage(message, chatbotResponse, false, reply);
  }

  private async _sendTextMessage(
    message: string,
    chatbotResponse: boolean,
    isMessageForGroupChatCreation: boolean,
    reply?: Reply,
    groupChatSubject?: string,
    groupChatIconUrl?: string
  ): Promise<SendMessageResult | undefined> {
    const user = getLocalUser();
    if (!user) {
      console.error("Unable to send message. Local user is undefined.");
      return;
    }
    const nmsMessage = NmsMessage.fromTextMessage(
      chatbotResponse
        ? getTextFromSuggestionResponse(
            JSON.parse(message) as SuggestionResponse
          )
        : message,
      user,
      this.id,
      reply
    );

    const sendMessageResult = await sendTextMessage(
      message,
      this.buildToPartForFirstMessageSending(isMessageForGroupChatCreation),
      chatbotResponse,
      this.isGroupChat,
      groupChatSubject,
      isMessageForGroupChatCreation ? groupChatIconUrl : undefined,
      this.conferenceUri,
      reply
    );

    if (!sendMessageResult) {
      console.error("Failed to send message", nmsMessage);

      if (!reply?.type?.includes("Reaction")) {
        const setImdn = setConversationMessageAsFailed(
          nmsMessage["imdn.Message-ID"],
          nmsMessage.To,
          new Date()
        );
        if (!setImdn) {
          console.error(
            "Failed to set imdn after failing to send message",
            nmsMessage
          );
        }
      }

      return sendMessageResult;
    }
    if (reply?.type === "Delete" || reply?.type === "Recall")
      return sendMessageResult;

    nmsMessage["imdn.Message-ID"] = sendMessageResult.messageId;
    let partial: Partial<NmsMessage>;

    const replaceId =
      reply?.type === "Edit" ? reply.id : nmsMessage["imdn.Message-ID"];
    if (reply?.type === "Edit") {
      // Don't keep references as this could be an edit over a reply, information will get lost
      delete nmsMessage["Reference-ID"];
      delete nmsMessage["Reference-Type"];

      // Keep track of latest message in history
      const refMessage = this.getMessage(replaceId);
      if (refMessage) {
        nmsMessage.history.push(refMessage);
      }
      partial = nmsMessage;
    } else {
      // For group chat creation we ll update database afterwards, no need to do it here
      this.pushMessage(nmsMessage, undefined, !isMessageForGroupChatCreation);
      partial = {
        "imdn.Message-ID": sendMessageResult.messageId,
        imdns: {
          imdn: [
            {
              // We got id back from the server which means message was properly sent (but not necessarily delivered to the remote party)
              imdnInfo: [
                {
                  type: "stored",
                  date: new Date().toISOString(),
                },
              ],
              originalTo: this.id,
            },
          ],
        },
      };
    }
    const setId = setConversationMessagePartial({
      msgId: replaceId,
      newMessage: partial,
      conversationId: this.id,
      updateDatabase: !isMessageForGroupChatCreation,
      removeFields: ["FileName"],
      discardOldMessageId: reply?.type !== "Edit",
    });
    if (!setId) {
      console.error(
        "Failed to set message id after sending message",
        nmsMessage
      );
    }

    return sendMessageResult;
  }

  public updateGroupInformation(
    conversationGroupChatInfos: ConversationGroupChatInfos,
    updateDatabase = true
  ) {
    let update = false;
    const oldId = this.id;
    const newId = conversationGroupChatInfos.contributionId || oldId;

    if (newId && newId !== oldId) {
      update = true;
      this.id = newId;
    }

    if (
      conversationGroupChatInfos.subject &&
      conversationGroupChatInfos.subject !== this.name
    ) {
      update = true;
      this.name = conversationGroupChatInfos.subject;
    }

    if (
      conversationGroupChatInfos.iconUrl &&
      conversationGroupChatInfos.iconUrl !== this.iconUrl
    ) {
      update = true;
      this.iconUrl = conversationGroupChatInfos.iconUrl;
    }

    if (
      conversationGroupChatInfos.isLocalUserAdmin &&
      conversationGroupChatInfos.isLocalUserAdmin !== this.isLocalUserAdmin
    ) {
      update = true;
      this.isLocalUserAdmin = conversationGroupChatInfos.isLocalUserAdmin;
    }

    if (
      conversationGroupChatInfos.isLocalUserJoined &&
      conversationGroupChatInfos.isLocalUserJoined !== this.isLocalUserJoined
    ) {
      update = true;
      this.isLocalUserJoined = conversationGroupChatInfos.isLocalUserJoined;
    }

    if (
      conversationGroupChatInfos.contactAdminId &&
      conversationGroupChatInfos.contactAdminId !== this.contactAdminId
    ) {
      update = true;
      this.contactAdminId = conversationGroupChatInfos.contactAdminId;
    }

    if (
      conversationGroupChatInfos.conferenceUri &&
      conversationGroupChatInfos.conferenceUri !== this.conferenceUri
    ) {
      update = true;
      this.conferenceUri = conversationGroupChatInfos.conferenceUri;
    }

    // For participants, lets compare for now the size of the contact ids
    if (
      conversationGroupChatInfos.participants &&
      (conversationGroupChatInfos.participants.length !==
        this.participants.length ||
        conversationGroupChatInfos.participants
          .slice()
          .map((contact) => contact.id)
          .sort()
          .join(",") !==
          this.participants
            .slice()
            .map((contact) => contact.id)
            .sort()
            .join(","))
    ) {
      update = true;
      this.participants = conversationGroupChatInfos.participants;
    }

    if (update) {
      // We always fallback on current date in case update is coming without any date
      // Enable this once we display in the conversation a message for one of the elements above (new icon, new subject, etc.)
      // this.updatedAt = conversationGroupChatInfos.date || new Date();
      console.log(
        `Updating group information with database update ${updateDatabase} for ${newId}: ${this}`
      );

      conversationsState.conversations.set(newId, this);

      if (updateDatabase) {
        void updateConversationInDatabase(newId, oldId);
      }
    }
  }

  public async createGroupChatAndSendLocation(
    latitude: number,
    longitude: number,
    groupChatSubject?: string,
    groupChatIconUrl?: string
  ): Promise<SendMessageResult | undefined> {
    const sendMessageResult = await this._sendLocation(
      latitude,
      longitude,
      true,
      groupChatSubject,
      groupChatIconUrl
    );

    if (sendMessageResult && sendMessageResult.contributionId) {
      this.updateGroupInformation({
        contributionId: sendMessageResult.contributionId,
        subject: groupChatSubject,
        iconUrl: groupChatIconUrl,
      });
    }

    return sendMessageResult;
  }

  public async sendLocation(
    latitude: number,
    longitude: number,
    reply?: Reply
  ): Promise<SendMessageResult | undefined> {
    return await this._sendLocation(
      latitude,
      longitude,
      false,
      undefined,
      undefined,
      reply
    );
  }

  private async _sendLocation(
    latitude: number,
    longitude: number,
    isMessageForGroupChatCreation: boolean,
    groupChatSubject?: string,
    groupChatIconUrl?: string,
    reply?: Reply
  ): Promise<SendMessageResult | undefined> {
    const user = getLocalUser();

    if (!user) {
      console.error("Unable to send message. Local user is undefined.");
      return;
    }

    const nmsMessage = new NmsMessage();
    const coordinates = { lat: latitude, lng: longitude };
    nmsMessage["imdn.Message-ID"] = nanoid();
    nmsMessage.Date = new Date();
    nmsMessage.Direction = "Out";
    nmsMessage.UserId = user;
    nmsMessage.From = user;
    nmsMessage.To = this.id;
    nmsMessage["Content-Type"] = "application/vnd.gsma.rcspushlocation+xml";
    nmsMessage.payloadParts = [
      {
        contentType: "application/vnd.gsma.rcspushlocation+xml",
        textContent: generateLocationPayload(this.id, latitude, longitude),
        href: generateMapUrl(coordinates),
        contentLocation: JSON.stringify(coordinates),
      },
    ];

    const sendMessageResult = await sendLocation(
      this.buildToPartForFirstMessageSending(isMessageForGroupChatCreation),
      latitude,
      longitude,
      this.isGroupChat,
      groupChatSubject,
      isMessageForGroupChatCreation ? groupChatIconUrl : undefined,
      this.conferenceUri,
      reply
    );

    if (!sendMessageResult) {
      // TODO handle error, failed to deliver message
      console.error("Failed to send message", nmsMessage);

      const setImdn = setConversationMessageAsFailed(
        nmsMessage["imdn.Message-ID"],
        nmsMessage.To,
        new Date()
      );
      if (!setImdn) {
        console.error(
          "Failed to set imdn after failing to send message",
          nmsMessage
        );
      }
      return;
    }

    nmsMessage["imdn.Message-ID"] = sendMessageResult.messageId;
    if (reply) {
      nmsMessage["Reference-Type"] = reply.type;
      nmsMessage["Reference-ID"] = reply.id;
    }
    this.pushMessage(nmsMessage, undefined, !isMessageForGroupChatCreation);

    const setId = setConversationMessagePartial({
      msgId: nmsMessage["imdn.Message-ID"],
      newMessage: {
        "imdn.Message-ID": sendMessageResult.messageId,
        imdns: {
          imdn: [
            {
              // We got id back from the server which means message was properly sent (but not necessarily delivered to the remote party)
              imdnInfo: [
                {
                  type: "stored",
                  date: new Date().toISOString(),
                },
              ],
              originalTo: this.id,
            },
          ],
        },
      },
      conversationId: this.id,
      updateDatabase: !isMessageForGroupChatCreation,
    });
    if (!setId) {
      console.error(
        "Failed to set message id after sending message",
        nmsMessage
      );
    }

    return sendMessageResult;
  }

  private buildToPartForFirstMessageSending(
    isMessageForGroupChatCreation = false
  ) {
    // When dealing with first message sending, group needs the list of participants separated by commas
    // For other cases, we always use the id (which is either the group contribution id or the remote phone number for 1-1)
    return isMessageForGroupChatCreation
      ? this.participants
          .map((participant) => {
            // The contact could have been saved with non international format but we matched it by providing the +, use it
            const phoneToUser = participant.userInputNumber?.startsWith("+")
              ? participant.userInputNumber
              : participant.getMainPhoneNumber();
            return formatPhoneNumber(phoneToUser, "E164");
          })
          .join(",")
      : this.id;
  }

  public async createGroupChatAndSendFile(
    file: File,
    groupChatSubject?: string,
    groupChatIconUrl?: string
  ): Promise<SendMessageResult | SendFileError | undefined> {
    const sendMessageResult = await this._sendFile(
      file,
      true,
      groupChatSubject,
      groupChatIconUrl
    );

    if (sendMessageResult && sendMessageResult.contributionId) {
      this.updateGroupInformation({
        contributionId: sendMessageResult.contributionId,
        subject: groupChatSubject,
        iconUrl: groupChatIconUrl,
      });
    }

    return sendMessageResult;
  }

  public async sendFile(
    file: File,
    reply?: Reply
  ): Promise<SendMessageResult | SendFileError | undefined> {
    return await this._sendFile(file, false, undefined, undefined, reply);
  }

  private async _sendFile(
    file: File,
    isMessageForGroupChatCreation = false,
    groupChatSubject?: string,
    groupChatIconUrl?: string,
    reply?: Reply
  ): Promise<SendMessageResult | SendFileError | undefined> {
    if (file.size > (await getMaxFileSizeAllowed())) {
      return SendFileError.TOO_BIG;
    }

    const imageAsNmsMessage = new NmsMessage();
    imageAsNmsMessage.Direction = "Out";
    imageAsNmsMessage.Date = new Date();
    imageAsNmsMessage.UserId = getLocalUser()!;
    imageAsNmsMessage.From = getLocalUser()!;
    imageAsNmsMessage.To = this.id;
    // Create message with temp id, real id will come from the server after upload
    imageAsNmsMessage["imdn.Message-ID"] = nanoid()!;
    imageAsNmsMessage["Content-Type"] = "application/vnd.gsma.rcs-ft-http+xml";
    imageAsNmsMessage.uploadProgress = 0;
    if (reply) {
      imageAsNmsMessage["Reference-Type"] = reply.type;
      imageAsNmsMessage["Reference-ID"] = reply.id;
    }
    imageAsNmsMessage.payloadParts = [
      {
        contentType: getMimeType(file),
        contentDisposition: "file",
        size: file.size,
        href: await fileToBase64(file),
      },
    ];

    imageAsNmsMessage.FileName = file.name;

    // For custom reaction no need for temp file progress on UI, either success or failure
    if (reply?.type !== "+Custom-Reaction") {
      this.pushMessage(
        imageAsNmsMessage,
        undefined,
        !isMessageForGroupChatCreation
      );
    }

    const progressCallback = (progress: number) => {
      setConversationMessagePartial({
        msgId: imageAsNmsMessage["imdn.Message-ID"],
        newMessage: {
          uploadProgress: progress,
        },
        conversationId: this.id,
        updateDatabase: false,
      });
    };

    try {
      const response = await fetchWithDigestAuthentication(
        "POST",
        file,
        // Custom reaction dont need thumbnail
        reply?.type !== "+Custom-Reaction",
        progressCallback,
        imageAsNmsMessage["imdn.Message-ID"]
      );

      if (!response || response.status < 200 || response.status > 300) {
        throw new Error();
      }

      const payload = await response.data;

      const sendMessageResult = await sendFile(
        payload,
        this.buildToPartForFirstMessageSending(isMessageForGroupChatCreation),
        this.isGroupChat,
        groupChatSubject,
        isMessageForGroupChatCreation ? groupChatIconUrl : undefined,
        this.conferenceUri,
        reply
      );
      if (sendMessageResult) {
        const messageID = sendMessageResult.messageId;

        // For custom reaction, save the nms message with payload image url
        if (reply?.type === "+Custom-Reaction") {
          imageAsNmsMessage["imdn.Message-ID"] = messageID;

          // Outgoing messages have base64 in their payload, replace with url to use it as key for reaction
          if (imageAsNmsMessage.Direction === "Out") {
            const url = getMainFileUrl(payload);
            if (url) {
              imageAsNmsMessage.payloadParts[0].href = url;
            }
          }

          this.pushMessage(
            imageAsNmsMessage,
            undefined,
            !isMessageForGroupChatCreation
          );
        } else {
          // There is no need here to update the message with final urls since object already
          // have them in base64. This also helps to not re-load the images from the server after upload.
          setConversationMessagePartial({
            msgId: imageAsNmsMessage["imdn.Message-ID"],
            newMessage: {
              "imdn.Message-ID": messageID,
              uploadProgress: 100,
              imdns: {
                imdn: [
                  {
                    imdnInfo: [
                      {
                        type: "stored",
                        date: new Date().toISOString(),
                      },
                    ],
                    originalTo: this.id,
                  },
                ],
              },
            },
            conversationId: this.id,
            updateDatabase: !isMessageForGroupChatCreation,
          });
        }

        return sendMessageResult;
      }
    } catch (error) {
      setConversationMessageAsFailed(
        imageAsNmsMessage["imdn.Message-ID"],
        imageAsNmsMessage.To,
        new Date(),
        true,
        (error as any)?.message === imageAsNmsMessage["imdn.Message-ID"]
      );
    }
  }

  public getLastMessageTimeMs() {
    return this.getMessages().at(-1)?.Date?.getTime() || 0;
  }

  public serialize() {
    return {
      id: this.id,
      participants: this.participants,
      messages: this.messages,
      isGroupChat: this.isGroupChat,
      name: this.name,
      iconUrl: this.iconUrl,
      isLocalUserAdmin: this.isLocalUserAdmin,
      isLocalUserJoined: this.isLocalUserJoined,
      contactAdminId: this.contactAdminId,
      conferenceUri: this.conferenceUri,
    };
  }

  /**
   * @param searchQuery
   * @returns true if the conversation has a participant that matches the search query
   */
  public filterConversation(searchQuery: string) {
    for (const contact of this.participants) {
      if (contact.filterContact(searchQuery)) {
        return true;
      }
    }
    return false;
  }

  public static from(obj: any) {
    const conversation = (
      Object.assign(Object.create(Conversation.prototype), obj) as Conversation
    ).construct();
    conversation.id = obj.id;
    conversation.setMessages(NmsMessage.toMsgList(obj.messages));

    for (let i = 0; i < conversation.participants.length; i++) {
      if (
        !(conversation.participants[i] instanceof WebGwContact) &&
        typeof conversation.participants[i] === "object"
      ) {
        conversation.participants[i] = WebGwContact.from(
          conversation.participants[i] as object
        );
      }
    }

    conversation.isGroupChat = obj.isGroupChat;
    conversation.name = obj.name;
    conversation.iconUrl = obj.iconUrl;
    conversation.isLocalUserAdmin = obj.isLocalUserAdmin;
    conversation.isLocalUserJoined = obj.isLocalUserJoined;
    conversation.contactAdminId = obj.contactAdminId;
    conversation.conferenceUri = obj.conferenceUri;

    return conversation;
  }

  public static getOrCreate({
    phoneNumber,
    contactToLinkIfCreate,
    initialMessage,
    groupChatInfos,
  }: {
    phoneNumber: string;
    contactToLinkIfCreate?:
      | WebGwContact
      | undefined
      | [WebGwContact, ...WebGwContact[]];
    initialMessage?: NmsMessage;
    groupChatInfos?: ConversationGroupChatInfos;
  }): {
    conversation: Conversation;
    isNew: boolean;
    updateConversationInDb?: Promise<void>;
  } {
    if (!phoneNumber) {
      throw new Error("Phone number must be provided");
    }

    phoneNumber = formatPhoneNumber(phoneNumber, "E164");

    const conversation = findConversationByPhoneNumber(phoneNumber);

    if (conversation) {
      console.log(
        "Conversation found for phone number ",
        phoneNumber,
        " with id ",
        conversation.id
      );

      let updateConversationInDb: Promise<void> | undefined;

      // It can happen that a conversation is created without the international format,
      // make sure here to update it as soon as we know the international format
      if (
        !conversation.isGroupChat &&
        !isChatbot(conversation.id) &&
        !conversation.id.startsWith("+") &&
        // Check if the phone formatted is international
        phoneNumber.startsWith("+")
      ) {
        const newId = this.formatPhoneNumberForId(phoneNumber);
        const currentId = conversation.id;
        conversation.id = newId;
        conversationsState.conversations.set(newId, conversation);
        conversationsState.conversations.delete(currentId);
        updateConversationInDb = updateConversationInDatabase(newId, currentId);
        console.log(`Replacing id of conversation ${currentId} with ${newId}`);
      }

      return { conversation, isNew: false, updateConversationInDb };
    } else {
      const isGroupChat = isPhoneNumberAGroupContributionId(phoneNumber);

      const newConversation = new Conversation(
        phoneNumber,
        contactToLinkIfCreate instanceof WebGwContact
          ? [contactToLinkIfCreate]
          : (contactToLinkIfCreate ?? [
              WebGwContact.fromPhoneNumber(phoneNumber)!,
            ]),
        // Messages come in sequence, meaning a message referencing another one cannot be first, ignoring in this case (original message got probably deleted)
        initialMessage && !initialMessage["Reference-Type"]
          ? [initialMessage]
          : [],
        isGroupChat,
        groupChatInfos?.subject,
        groupChatInfos?.iconUrl,
        groupChatInfos?.isLocalUserAdmin,
        groupChatInfos?.isLocalUserJoined,
        groupChatInfos?.contactAdminId,
        groupChatInfos?.conferenceUri
      );

      console.trace("New conversation created ", newConversation);

      conversationsState.conversations.set(newConversation.id, newConversation);

      // Make sure to return the proxy and not directly the value from Conversation.create
      return {
        conversation: conversationsState.conversations.get(newConversation.id)!,
        isNew: true,
      };
    }
  }

  public static createTempForGroupChat(participants: WebGwContact[]) {
    // A group needs at least two participants
    if (participants.length < 2) {
      return;
    }

    const conversation = new Conversation(
      generateRandomString(6),
      [participants[0], ...participants.slice(1)],
      [],
      true
    );

    conversationsState.conversations.set(conversation.id, conversation);

    return {
      conversation: conversationsState.conversations.get(conversation.id)!,
      isNew: true,
    };
  }

  private static formatPhoneNumberForId(phoneNumber: string) {
    return cleanPhoneNumber(phoneNumber);
  }
}

export type ConversationGroupChatInfos = {
  contributionId: string;
  conferenceUri?: string;
  subject?: string;
  iconUrl?: string;
  isLocalUserJoined?: boolean;
  isLocalUserAdmin?: boolean;
  contactAdminId?: string;
  participants?: [WebGwContact, ...WebGwContact[]];
  date?: Date;
};
